export const validationRules = {
  data() {
    return {
      rules: {
        required: (value) => !!value || "This area is required.",
        detailsRule: (v) =>
          !v || v?.toString().length <= 10 || this.$t("Too much"),
        min_8: (v) => !v || v.length >= 8 || "Min 8 characters",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
        phone: (v) =>
          !v ||
          /^(\+?(\d{1,3}))[-./ ]?[1-9]{2}([-./ ]?[0-9]{2}){3}$/.test(v) ||
          /^(\+?(\d{1,3}))[-./ ]?[5-9]([-./ ]?[0-9]{2}){4}$/.test(v) ||
          "Phone must be valid",
        quantity: (v) => !v || v > 0 || "Not valid",
        max_quantity(max) {
          return (v) => v <= max || "Maximum " + max + " items ";
        },
        min_quantity(min) {
          return (v) => v >= min || "Minimum " + min + " items ";
        },
        min_price(min) {
          return (v) => v >= min || "min price " + min;
        },

        percentage: (v) => (v >= 0 && v <= 100) || "Not valid percentage",

        superior_to(min) {
          return (v) => v >= parseFloat(min) || "should be superior to " + min;
        },
        same_as_password(password) {
          return (v) => v == password || "confirmation password does not match";
        },
        not_empty: (v) => !!(v && v.length) || this.$t("not_empty_rule"),
        alpha_num: (v) =>
          !!(v && v.length && /^[a-zA-Z0-9-//]+$/.test(v)) ||
          "only alphanumeric allowed",

        // not working
        vat_array: (v) =>
          (v.length > 0 &&
            function (v) {
              for (var i = 0; i < v.length; i++) {
                console.log(v[i]);
                if (v[i] <= 100 && v[i] >= 0) return true;
              }
            }) ||
          "must_be_list_of_numeric",

        field_length_between: (field_name, min, max, v) => {
          return (
            (v?.length <= max && v?.length >= min) ||
            this.$t("field_length_between", {
              min: min,
              max: max,
              field: field_name,
            })
          );
        },
        field_required: (name, v) =>
          !!v || this.$t("is_required", { name: this.$t(name) }),
      },
    };
  },
};
